<template>
  <v-form>
    <v-container fluid>
      <!-- burn Annotations -->
      <v-checkbox
        v-model="burnAnnotations"
        :label="labelIncludeAnnotations"
        :disabled="!manageAnnotations"
      ></v-checkbox>

      <!-- burn Redactions -->
      <v-checkbox
        class="mt-0"
        v-model="burnRedaction"
        :label="labelIncludeRedaction"
        :disabled="!manageRedactions"
      ></v-checkbox>
    </v-container>
  </v-form>
</template>

<script>
import {
  createPrintOption,
  findPrintOption,
  printOption
} from "@/model/image/imageModel";

export default {
  name: "PrintAnnotationOption",
  props: {
    /**
     * @type {{burnAnnotations: boolean, burnRedaction: boolean, getEditFilePath: boolean}}
     */
    printOption: createPrintOption(),

    /**
     * is current user authorized to manage Annotations
     */
    manageAnnotations: {
      type: Boolean,
      default: false
    },

    /**
     * is current user authorized to manage Redactions
     */
    manageRedactions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * label Include Redaction
     * @return {string}
     */
    labelIncludeRedaction() {
      return findPrintOption(printOption.burnRedaction)?.label;
    },

    /**
     * Get/set burn Redaction
     */
    burnRedaction: {
      get() {
        return this.printOption.burnRedaction;
      },
      set(value) {
        this.printOption.burnRedaction = value;
      }
    },

    labelIncludeAnnotations() {
      return findPrintOption(printOption.burnAnnotations)?.label;
    },

    /**
     * Get/set burn Annotations
     */
    burnAnnotations: {
      get() {
        return this.printOption.burnAnnotations;
      },
      set(value) {
        this.printOption.burnAnnotations = value;
      }
    }
  }
};
</script>

<style scoped></style>
